/* eslint-disable react/jsx-no-literals */
import { CurrentUser } from 'Roblox';
import React, { Fragment } from 'react';
import * as itemPurchase from 'roblox-item-purchase';
import { numberFormat } from 'core-utilities';
import { eventStreamService } from 'core-roblox-utilities';
import { TItemPricing, TItemPurchaseParams, TPriceDisplayMode } from '../constants/types';
import ItemFirstLine from './ItemFirstLine';
import { useItemPricing } from '../utils/hooks';
import { TItemPriceContainerProps } from './ItemDetailsInfoBody';
import {
  catalogTranslations,
  itemTranslations,
  itemModelTranslations
} from '../services/translationService';
import {
  regularPriceDisplayModes,
  nonPurchasableDisplayModes,
  consumableDisplayModes
} from '../constants/pricingConstants';
import PurchaseButton, { UnathentictedPurchaseButton } from './PurchaseButton';
import PriceSubText from './PriceSubText';
import { isLimited, isCollectible } from '../utils/itemDetailUtils';
import OwnedItemButton from './OwnedItemButton';
import { genPurchaseParams } from '../utils/purchaseUtils';
// import AddToCartButton from './AddToCartButton';
import AddToCartButton from '../../shoppingCart/components/AddToCartButton';
import { ResaleRestriction } from '../constants/resaleRestrictionConstants';

const [ItemPurchase, itemPurchaseService] = itemPurchase.createItemPurchase();
const formatNumber = numberFormat.getNumberFormat;

function ItemPrice({
  expectedPrice,
  itemPurchaseParams,
  priceDisplayMode,
  renderPurchaseLink,
  resaleRestriction,
  isInExperienceOnly,
  isCollectibleAndOffSale
}: {
  expectedPrice: number | null;
  itemPurchaseParams: TItemPurchaseParams | null;
  priceDisplayMode: TPriceDisplayMode | null;
  renderPurchaseLink: boolean | undefined;
  resaleRestriction: ResaleRestriction;
  isInExperienceOnly: boolean;
  isCollectibleAndOffSale: boolean;
}) {
  if (typeof expectedPrice === 'number') {
    if (expectedPrice === 0) {
      return (
        <div className='item-price-value'>
          <span className='text'>{itemTranslations.labelFree()}</span>
        </div>
      );
    }

    const priceVisible =
      priceDisplayMode === 'COLLECTIBLE_ONLY_ORIGINAL' &&
      resaleRestriction === ResaleRestriction.DISABLED;

    // Gray out price text if original copy cannot be obtained due to various reasons
    const priceClassName = `text-robux-lg ${
      itemPurchaseParams ||
      priceDisplayMode !== 'COLLECTIBLE_ONLY_ORIGINAL' ||
      priceVisible ||
      !renderPurchaseLink
        ? ''
        : 'disabled'
    }`;

    return (
      <div className='item-price-value icon-text-wrapper clearfix icon-robux-price-container'>
        <span className='icon-robux-16x16' />
        <span className={priceClassName}>{formatNumber(expectedPrice)}</span>
        {!!itemPurchaseParams && !isInExperienceOnly && !isCollectibleAndOffSale && (
          <div className='item-purchase-link-container'>
            <span
              className='see-all-link-icon'
              onClick={() => {
                itemPurchaseService.start();
              }}
              aria-hidden='true'
            />
            <ItemPurchase {...itemPurchaseParams} />
          </div>
        )}
      </div>
    );
  }
  return null;
}

function getExpectedPrice(itemPricingInfo: TItemPricing): number | null {
  const { priceDisplayMode, premiumPrice, price, lowestPrice, lowestResalePrice } = itemPricingInfo;
  const defaultPrice = price;
  if (price === 0 && (lowestPrice === undefined || lowestPrice === null)) {
    return 0;
  }
  if (priceDisplayMode === 'PREMIUM_USER_PREMIUM_ITEM') {
    return premiumPrice ?? defaultPrice;
  }
  if (priceDisplayMode === 'COLLECTIBLE_ONLY_RESELLERS_QUANTITY_LIMIT_REACHED') {
    return lowestResalePrice ?? defaultPrice;
  }
  if (priceDisplayMode === 'COLLECTIBLE_ONLY_RESELLERS') {
    const isOnlyInExperience = itemPricingInfo.saleLocationType === 'ExperiencesDevApiOnly';
    if (isOnlyInExperience) {
      return lowestResalePrice ?? defaultPrice;
    }
    return lowestPrice ?? defaultPrice;
  }
  if (priceDisplayMode === 'LIMITED_WITH_RESELLERS') {
    return lowestPrice ?? defaultPrice;
  }
  if (regularPriceDisplayModes.includes(priceDisplayMode)) {
    return price;
  }
  if (consumableDisplayModes.includes(priceDisplayMode)) {
    return price;
  }
  return defaultPrice;
}

const ItemPriceContainer = (props: TItemPriceContainerProps): JSX.Element | null => {
  const {
    itemDetails,
    collectibleItemDetails,
    permissions,
    ownedItemInstances,
    resellers,
    reachedQuantityLimit,
    originalItemDetails
  } = props;

  const isExperienceLinkEnabled = !!collectibleItemDetails?.experiences?.length;

  const itemPricingInfo = useItemPricing(
    itemDetails,
    collectibleItemDetails,
    permissions,
    ownedItemInstances,
    resellers,
    !!originalItemDetails,
    isExperienceLinkEnabled
  );

  const expectedPrice = getExpectedPrice(itemPricingInfo);
  const isItemLimited = isLimited(itemDetails.itemRestrictions);
  const isItemCollectible = isCollectible(itemDetails.itemRestrictions);
  const isCollectibleAndOffSale = isItemCollectible && itemDetails.isOffSale === true;
  const resaleRestriction = collectibleItemDetails
    ? collectibleItemDetails.resaleRestriction
    : ResaleRestriction.NONE;
  const isInExperienceOnly = itemPricingInfo.saleLocationType === 'ExperiencesDevApiOnly';
  const isIEPWithHyperLinks = isInExperienceOnly && !!collectibleItemDetails?.experiences?.length;

  const onlyFromReseller =
    itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_ONLY_RESELLERS_QUANTITY_LIMIT_REACHED';
  const hasResellers = !!resellers?.length;

  const itemPurchaseBestParams = genPurchaseParams({
    itemDetails,
    expectedPrice,
    resellers,
    collectibleItemDetails,
    onlyFromReseller
  });

  const checkIfItemShouldPurchaseFromReseller = () => {
    if (
      itemDetails.itemRestrictions.includes('LimitedUnique') ||
      itemDetails.itemRestrictions.includes('Limited')
    ) {
      return true;
    }
    if (itemDetails.collectibleItemId !== undefined && collectibleItemDetails !== undefined) {
      if (
        collectibleItemDetails.unitsAvailableForConsumption !== undefined &&
        collectibleItemDetails.unitsAvailableForConsumption > 0
      ) {
        if (
          (collectibleItemDetails.lowestResalePrice !== undefined &&
            collectibleItemDetails.lowestResalePrice > collectibleItemDetails?.price) ||
          collectibleItemDetails.saleLocationType === 'ExperiencesDevApiOnly'
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  if (itemPurchaseBestParams) {
    itemPurchaseBestParams.onPurchaseSuccess = () => {
      const params = {
        totalTransactionValue: itemPurchaseBestParams.expectedPrice,
        transactionItems: JSON.stringify([
          {
            itemType: itemDetails.itemType,
            itemId: itemDetails.id,
            resalePurchase: checkIfItemShouldPurchaseFromReseller()
          }
        ]),
        purchaseType: 'item-details-page-purchase',
        userId: CurrentUser.userId
      };
      eventStreamService.sendEvent(
        {
          name: 'marketplaceWebPurchaseSuccess',
          type: 'marketplaceWebPurchaseSuccess',
          context: 'marketplaceWebPurchase'
        },
        params
      );
    };
  }

  // An optional purchase link on original copy even if it's not the best price
  const renderPurchaseLink =
    itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_ONLY_ORIGINAL' &&
    !!resellers?.length &&
    resaleRestriction !== ResaleRestriction.DISABLED;
  const itemPurchaseLinkParams =
    renderPurchaseLink && itemPricingInfo.unitsAvailableForConsumption && !reachedQuantityLimit
      ? genPurchaseParams({
          itemDetails,
          expectedPrice,
          resellers: [],
          collectibleItemDetails,
          onlyFromReseller: false
        })
      : null;

  let priceLabel = itemTranslations.labelPrice();
  if (
    itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_ONLY_RESELLERS' &&
    ((isInExperienceOnly && isExperienceLinkEnabled) || isCollectibleAndOffSale)
  ) {
    priceLabel = itemModelTranslations.labelResellers();
  } else if (
    itemPricingInfo.priceDisplayMode === 'LIMITED_WITH_RESELLERS' ||
    itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_ONLY_RESELLERS'
  ) {
    priceLabel = itemTranslations.labelBestPrice();
  } else if (
    itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_ONLY_ORIGINAL' &&
    resaleRestriction !== ResaleRestriction.DISABLED
  ) {
    priceLabel = catalogTranslations.labelOriginalPrice();
  }

  const purchaseButton =
    renderPurchaseLink ||
    itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_IN_EXPERIENCE_ONLY_ORIGINAL' ||
    (isIEPWithHyperLinks &&
      !!collectibleItemDetails?.unitsAvailableForConsumption &&
      (itemPricingInfo?.lowestResalePrice ?? 0) > (itemPricingInfo.price ?? 0)) ||
    (isCollectibleAndOffSale &&
      itemPricingInfo.priceDisplayMode === 'COLLECTIBLE_ONLY_ORIGINAL') ? (
      <React.Fragment />
    ) : (
      <div className='shopping-cart-buy-button item-purchase-btns-container'>
        {itemPricingInfo.priceDisplayMode === 'UNAUTHENTICATED_USER_PREMIUM_ITEM' ||
        !CurrentUser.isAuthenticated ? (
          <UnathentictedPurchaseButton {...props} itemPricingInfo={itemPricingInfo} />
        ) : (
          <PurchaseButton itemPurchaseParams={itemPurchaseBestParams} />
        )}
        <AddToCartButton
          itemId={itemDetails.id}
          itemType={itemDetails.itemType}
          itemName={itemDetails.name}
          collectibleItemId={itemDetails.collectibleItemId}
        />
      </div>
    );

  return (
    <div className='price-row-container'>
      <div className='price-container-text'>
        <ItemFirstLine
          itemOwned={itemDetails.owned}
          itemPricingInfo={itemPricingInfo}
          reachedQuantityLimit={reachedQuantityLimit}
          resaleRestriction={resaleRestriction}
          unitsAvailableForConsumption={itemPricingInfo.unitsAvailableForConsumption}
          experienceLinkEnabled={isExperienceLinkEnabled}
          isCollectibleAndOffSale={isCollectibleAndOffSale}
          hasResellers={hasResellers}
        />
        {typeof expectedPrice === 'number' &&
          !nonPurchasableDisplayModes.includes(itemPricingInfo.priceDisplayMode) && (
            <div className='item-info-row-container'>
              <div className='text-label row-label price-label'>{priceLabel}</div>
              <div className='price-info row-content'>
                <Fragment>
                  <ItemPrice
                    expectedPrice={expectedPrice}
                    itemPurchaseParams={itemPurchaseLinkParams}
                    priceDisplayMode={itemPricingInfo.priceDisplayMode}
                    renderPurchaseLink={renderPurchaseLink}
                    resaleRestriction={resaleRestriction}
                    isInExperienceOnly={isInExperienceOnly}
                    isCollectibleAndOffSale={isCollectibleAndOffSale}
                  />
                  <PriceSubText
                    {...props}
                    expectedPrice={expectedPrice}
                    itemPricingInfo={itemPricingInfo}
                    isIEPWithHyperLinks={isIEPWithHyperLinks}
                  />
                  {purchaseButton}
                </Fragment>
              </div>
            </div>
          )}
      </div>
      {itemDetails.owned && !isItemLimited && !isItemCollectible && (
        <OwnedItemButton
          assetType={(itemDetails?.itemType === 'Asset' && itemDetails?.assetType) || null}
          isBundle={itemDetails?.itemType === 'Bundle'}
        />
      )}
    </div>
  );
};
export default ItemPriceContainer;
